.fw-normal {
    font-weight: 400!important;
}
.landing-dark-border {
    border: 1px dashed #2c3f5b;
}
.fs-lg-2qx {
    font-size: 2.25rem!important;
}
.text-gray-700 {
    color: #5e6278!important;
    color: var(--kt-text-gray-700)!important;
}
.fs-5 {
    font-size: 1.15rem!important;
}
.fs-4 {
    font-size: 1.25rem!important;
}
.min-w-70px {
    min-width: 70px!important;
}
.fw-semibold {
    font-weight: 500!important;
}
.fw-bold {
    font-weight: 600!important;
}
.lh-0 {
    line-height: 0!important;
}
.fs-2hx {
    font-size: 2.5rem!important;
}
.fs-lg-2hx {
    font-size: 2.5rem!important;
}